<script setup>
import { computed, reactive } from "vue";

const props = defineProps({
  visible: {
    type: Boolean,
    default: false,
  },
  loading: {
    type: Boolean,
    default: false,
  },
});
const emit = defineEmits(["update:visible", "is:create"]);

const state = reactive({
  username: null,
});

const visible = computed({
  get: () => props.visible,
  set: (v) => emit("update:visible", v),
});

const clear = () => {
  state.username = null;
  visible.value = false;
};

const createChat = () => {
  emit("is:create", state.username);
  clear();
};
</script>

<template>
  <a-modal
    v-model:visible="visible"
    ok-text="Sohbeti Oluştur"
    cancel-text="İptal"
    @ok="createChat"
    @cancel="clear"
    centered
  >
    <template #title>
      <h3 class="no-margin is-heading-light">Sohbet Oluştur</h3>
    </template>
    <a-spin :spinning="loading">
      <p class="is-description">
        Aşağıda bulunan metin alanına, sohbet oluşturmak istediğiniz üye adını
        girebilirsiniz.
      </p>
      <a-input
        v-model:value="state.username"
        placeholder="Sohbet etmek istediğiniz üye adını giriniz"
        class="no-outline width-100"
        :bordered="false"
        @keydown.enter="createChat"
      ></a-input>
    </a-spin>
  </a-modal>
</template>
